const { cacheNames } = require('workbox-core');
const { ExpirationPlugin } = require('workbox-expiration');
const { NavigationRoute, registerRoute } = require('workbox-routing');
const { CacheFirst } = require('workbox-strategies');
const googleAnalytics = require('workbox-google-analytics');
const {
  createHandlerBoundToURL,
  precacheAndRoute,
} = require('workbox-precaching');

// this file is injected into by workbox-webpack-plugin
/* globals importScripts, localforage */
importScripts('/localforage.min.js');
const log = (...msgs) => console.log('[SW]', ...msgs); // eslint-disable-line no-console

const originalFetch = fetch;
// eslint-disable-next-line no-global-assign
fetch = (...args) => {
  const firstArg = args[0];
  const url = firstArg.url || firstArg;

  if (url.includes('offline=true')) {
    throw new Error('offline');
  }

  return originalFetch(...args);
};

// precache files from manifest generated by workbox-webpack-plugin
precacheAndRoute(self.__WB_MANIFEST || []);

// support Google Analytics calls when offline
// try/catch because adblock can make this fail
try {
  googleAnalytics.initialize();
} catch (error) {
  // eslint-disable-next-line no-console
  log('Failed to initialize Google Analytics', error);
}

// register route for reports output
registerRoute(
  new NavigationRoute(createHandlerBoundToURL('/reports/output/index.html'), {
    allowlist: [/\/reports\/output\/?/],
  }),
);

// fallback navigation to index (enable SPA support)
registerRoute(
  new NavigationRoute(createHandlerBoundToURL('/index.html'), {
    // exclude API routes and files
    denylist: [/\/apis\//i, /\/vim2\//i, /\.js$/i, /\.css$/i, /\.json$/i],
  }),
);

const isLocalhost = Boolean(
  self.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    self.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    self.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

const copyBody = async request => {
  const body = await request.clone().blob();

  if (body.size) {
    return body;
  }
  return Promise.resolve(undefined);
};

// Request properties are not iterable
// this function copies all properties that we can use in Request constructor
// into a simple object so that it has iterable properties
// and we can do e.g. new Request(url, { ...iterableRequest(someRequest), headers: newHeaders })
// https://developer.mozilla.org/en-US/docs/Web/API/Request/Request
// it also helps work around Safari's retardation
// https://github.com/GoogleChrome/workbox/issues/1732
const iterableRequest = async (request, newProps) => {
  const body = await copyBody(request);

  const {
    url,
    method,
    headers,
    mode,
    credentials,
    cache,
    redirect,
    referrer,
    integrity,
  } = request;

  // object spread may not be supported (Edge), so use Object.assign instead
  return Object.assign(
    {
      url,
      method,
      headers,
      body,
      mode,
      credentials,
      cache,
      redirect,
      referrer,
      integrity,
    },
    newProps,
  );
};

const makeNotFoundResponse = () =>
  new Response(undefined, { status: 404, statusText: 'Not Found' });

// caching POST requests in Cache Storage is currently not allowed: https://github.com/w3c/ServiceWorker/issues/693
// and that's what workbox uses, so we need our own workaround implementation with IndexedDB
// here goes:
const idbCache = {
  strategies: {
    networkOnly: ({ plugins }) => {
      const logger = idbCache._log;
      const handler = async context => {
        logger('NetworkOnly responding to ' + context.url);
        let { request } = context.event;
        request = await idbCache._callPlugins(
          plugins,
          'requestWillFetch',
          context.event,
        );
        // extra transformation because Safari is retarded
        // https://github.com/GoogleChrome/workbox/issues/1732
        const iRequest = await iterableRequest(request);
        const response = await fetch(iRequest.url, iRequest);
        await idbCache._callPlugins(plugins, 'requestDidFetch', {
          request: context.event.request,
          response,
          event: context.event,
        });

        return response;
      };

      handler.handle = handler; // to make shape the same as workbox's handlers

      return handler;
    },
    networkFirst: ({ cacheName, plugins }) => {
      const logger = idbCache._log;
      const handler = async context => {
        logger('NetworkFirst responding to ' + context.url);
        const store = idbCache._ensureStore(cacheName);
        let { request } = context.event;
        const reqId = await idbCache._getRequestId(request);

        try {
          request = await idbCache._callPlugins(
            plugins,
            'requestWillFetch',
            context.event,
          );
          // extra transformation because Safari is retarded
          // https://github.com/GoogleChrome/workbox/issues/1732
          const iRequest = await iterableRequest(request);
          const res = await fetch(iRequest.url, iRequest);
          const isSuccessStatus = res.status >= 200 && res.status < 300;
          if (isSuccessStatus) {
            idbCache._storeResponse(store, reqId, res);
            await idbCache._callPlugins(plugins, 'cacheDidUpdate', { store });
          }
          return res;
        } catch (e) {
          logger('request failed, falling back to cache', e);
          const cachedRes = await idbCache._retrieveResponse(store, reqId);
          return cachedRes || makeNotFoundResponse();
        }
      };

      handler.handle = handler; // to make shape the same as workbox's handlers

      return handler;
    },
    // when online -> only use network
    // when offline -> only use cache
    networkWithOfflineCache: ({
      cacheName,
      cacheResponses = false,
      plugins,
    }) => {
      const logger = idbCache._log;
      const handler = async context => {
        logger('NetworkWithOfflineCache responding to ' + context.url);
        const store = idbCache._ensureStore(cacheName);
        let { request } = context.event;
        const reqId = await idbCache._getRequestId(request);

        if (navigator.onLine && !request.url.includes('offline=true')) {
          request = await idbCache._callPlugins(
            plugins,
            'requestWillFetch',
            context.event,
          );
          // extra transformation because Safari is retarded
          // https://github.com/GoogleChrome/workbox/issues/1732
          const iRequest = await iterableRequest(request);
          const res = await fetch(iRequest.url, iRequest);
          const isSuccessStatus = res.status >= 200 && res.status < 300;
          if (isSuccessStatus && cacheResponses) {
            idbCache._storeResponse(store, reqId, res);
            await idbCache._callPlugins(plugins, 'cacheDidUpdate', { store });
          }
          return res;
        } else {
          const cachedRes = await idbCache._retrieveResponse(store, reqId);
          return cachedRes || makeNotFoundResponse();
        }
      };

      handler.handle = handler; // to make shape the same as workbox's handlers

      return handler;
    },
    cacheFirst: ({ cacheName, plugins }) => {
      const logger = idbCache._log;
      const handler = async context => {
        logger('CacheFirst responding to ' + context.url);
        const store = idbCache._ensureStore(cacheName);
        let { request } = context.event;
        const reqId = await idbCache._getRequestId(request);

        await idbCache._callPlugins(plugins, 'cacheKeyWillBeUsed', { store });

        const cachedRes = await idbCache._retrieveResponse(store, reqId);

        if (cachedRes) {
          return cachedRes;
        }

        logger('request not found in cache, falling back to network');

        request = await idbCache._callPlugins(
          plugins,
          'requestWillFetch',
          context.event,
        );

        // extra transformation because Safari is retarded
        // https://github.com/GoogleChrome/workbox/issues/1732
        const iRequest = await iterableRequest(request);
        const response = await fetch(iRequest.url, iRequest);
        const isSuccessStatus = response.status >= 200 && response.status < 300;
        if (isSuccessStatus) {
          idbCache._storeResponse(store, reqId, response);
          await idbCache._callPlugins(plugins, 'cacheDidUpdate', { store });
        }
        return response;
      };

      handler.handle = handler; // to make shape the same as workbox's handlers

      return handler;
    },
    cacheOnly: ({ cacheName }) => {
      const logger = idbCache._log;
      const handler = async context => {
        logger('CacheOnly responding to ' + context.url);
        const store = idbCache._ensureStore(cacheName);
        const { request } = context.event;
        const reqId = await idbCache._getRequestId(request);
        const cachedRes = await idbCache._retrieveResponse(store, reqId);
        return cachedRes || makeNotFoundResponse();
      };

      handler.handle = handler; // to make shape the same as workbox's handlers

      return handler;
    },
  },
  plugins: {
    expiration({ maxEntries, maxAgeSeconds }) {
      let cacheStore;
      const handler = async ({ store }) => {
        cacheStore = store;
        if (!store || !store.keys) return;

        if (maxEntries) {
          const keys = await store.keys();
          const entryCount = keys.length;
          if (entryCount > maxEntries) {
            const removeCount = entryCount - maxEntries;
            for (let i = 0; i < removeCount; i++) {
              await store.removeItem(keys[i]);
            }
          }
        }
        if (maxAgeSeconds) {
          const nowSeconds = Math.ceil(new Date().getTime() / 1000);
          await store.iterate(async (value, key) => {
            const { headers = {} } = value;
            const ageSeconds = Math.floor(
              new Date(headers.date).getTime() / 1000,
            );
            if (nowSeconds - ageSeconds > maxAgeSeconds) {
              await store.removeItem(key);
            }
          });
        }
      };

      return {
        cacheDidUpdate: handler,
        cacheKeyWillBeUsed: handler,
        deleteCache: async () => {
          if (cacheStore) {
            cacheStore.clear();
          }
        },
      };
    },
    storeResponse({
      cacheName,
      transformRequest = r => r,
      transformResponse = r => r,
    }) {
      return {
        requestDidFetch: async ({ request, response }) => {
          const isSuccessStatus =
            response.status >= 200 && response.status < 300;
          if (isSuccessStatus) {
            const store = await idbCache._ensureStore(cacheName);
            const transformedRequest = await transformRequest(request);
            const reqId = await idbCache._getRequestId(transformedRequest);
            const transformedResponse = await transformResponse(response);
            idbCache._storeResponse(store, reqId, transformedResponse);
          }
        },
      };
    },
  },
  _callPlugins: async (plugins = [], lifecycle, arg) => {
    let result;

    for (let i = 0; i < plugins.length; i++) {
      if (plugins[i][lifecycle]) {
        result = await plugins[i][lifecycle](result || arg);
      }
    }

    return result;
  },
  _ensureStore: name =>
    localforage.createInstance({
      name: 'sbl-' + name,
      storeName: name,
    }),
  _log: (...args) => {
    if (isLocalhost) {
      console.log('[idbCache]', ...args); // eslint-disable-line no-console
    }
  },
  _getRequestId: async req => {
    const body = await req.clone().text();
    const id = req.url + idbCache._hashString(body);

    return id;
  },
  // https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery
  _hashString: str =>
    (str || '')
      .split('')
      .reduce(
        (prevHash, currVal) =>
          (prevHash << 5) - prevHash + currVal.charCodeAt(0),
        0,
      ),
  _storeResponse: async (store, id, res) => {
    const serialized = await idbCache._serializeResponse(res);
    await store.setItem(id, serialized);
  },
  _retrieveResponse: async (store, id) => {
    const serialized = await store.getItem(id);
    if (serialized) {
      return idbCache._deserializeResponse(serialized);
    }
  },
  _serializeResponse: async res => {
    const headers = {};

    for (const entry of res.headers.entries()) {
      headers[entry[0]] = entry[1];
    }
    const serialized = {
      headers: headers,
      status: res.status,
      statusText: res.statusText,
    };

    const body = await res.clone().text();
    serialized.body = body;

    return serialized;
  },
  _deserializeResponse: serialized => new Response(serialized.body, serialized),
};

const monthInSeconds = 30 * 24 * 60 * 60;
const dictionariesExpirationPlugin = idbCache.plugins.expiration({
  maxAgeSeconds: monthInSeconds * 6,
});
const requestExpirationPlugin = idbCache.plugins.expiration({
  maxEntries: 500,
  maxAgeSeconds: monthInSeconds,
});

self.addEventListener('install', () => {
  self.skipWaiting();
});

self.addEventListener('activate', event => {
  log('activate');
  //deleteing workbox cache
  event.waitUntil(caches.delete(cacheNames.precache));
});

const batchRegisterRoute = (matcher, handler, methods) =>
  methods.forEach(method => registerRoute(matcher, handler, method));

// passthrough the request on localhost,
// replace url with value of x-target-url on other envs
const replaceTargetUrlPlugin = () => ({
  requestWillFetch: async ({ request }) => {
    if (!isLocalhost) {
      let url = request.url;
      const headers = new Headers();
      for (let h of request.headers.entries()) {
        if (h[0] === 'x-target-url') {
          url = h[1];
        } else if (!headers.has(h[0])) {
          headers.append(h[0], h[1]);
        }
      }
      // extra transformation because Safari is retarded
      // https://github.com/GoogleChrome/workbox/issues/1732
      const iRequest = await iterableRequest(request, { headers });
      const req = new Request(url, iRequest);
      return req;
    } else {
      // this part is just for debug - applying similar transformation as above to catch more potential errors
      // extra transformation because Safari is retarded
      // https://github.com/GoogleChrome/workbox/issues/1732
      const iRequest = await iterableRequest(request);
      const req = new Request(iRequest.url, iRequest);
      return req;
    }
  },
});

// registerRoute order matters - first match handles the route, rest will not be called
//
// CORE
//
const authEndpointRegex = /\/apis\/api\/users\/\S+\/authenticate/;
const logoutEndpointRegex = /\/apis\/api\/users\/logout/;
const selfsessionEndpointRegex = /\/apis\/api\/users\/self_/;
const coreApiRegex = /\/apis\/api\//;
const coreCacheName = 'core-api-cache';
const pointlessSelfsessionGuid = 'A1A1A1A1-B2B2-C3C3-D4D4-E5E5E5E5E5E5';

// allows us to store /authenticate response as /selfsession response
// by transforming one into the other
const authRequestTransformer = async request => {
  const coreApiPath = '/api/';
  const url =
    request.url.slice(
      0,
      request.url.indexOf(coreApiPath) + coreApiPath.length,
    ) + `users/self_${pointlessSelfsessionGuid}/sessions/current`;
  // extra transformation because Safari is retarded
  // https://github.com/GoogleChrome/workbox/issues/1732
  const iRequest = await iterableRequest(request, {
    method: 'GET',
    body: undefined,
  });
  const req = new Request(url, iRequest);

  return req;
};

registerRoute(
  selfsessionEndpointRegex,
  idbCache.strategies.networkWithOfflineCache({
    cacheName: coreCacheName,
    plugins: [replaceTargetUrlPlugin()],
  }),
  'GET',
);

registerRoute(
  authEndpointRegex,
  idbCache.strategies.networkOnly({
    cacheName: coreCacheName,
    plugins: [
      replaceTargetUrlPlugin(),
      idbCache.plugins.storeResponse({
        cacheName: coreCacheName,
        transformRequest: authRequestTransformer,
      }),
    ],
  }),
  'POST',
);

registerRoute(
  logoutEndpointRegex,
  idbCache.strategies.networkOnly({
    plugins: [replaceTargetUrlPlugin()],
  }),
  'GET',
);

batchRegisterRoute(
  coreApiRegex,
  idbCache.strategies.networkOnly({
    plugins: [replaceTargetUrlPlugin()],
  }),
  ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
);

//
// ESB
//
const esbApiRegex = /\/apis\/esb\//;
// TODO: change cache strategy for rosters and profile APIs to only affect first load of page
// const personProfileRegex = /persons[/]v2[/].*[/]personprofile/;
// const rostersRegex = [
//   /organizations[/]v2[/]units[/].*[/](adults|youths)/,
//   /advancements[/].*[/](adults|youths)/,
// ];
// cache dictionary data
const dictRoutes = [
  new RegExp('/apis/esb/lookups/'),
  new RegExp('/apis/esb/advancements/meritBadges'),
  new RegExp('/apis/esb/advancements/awards'),
  new RegExp('/apis/esb/advancements/ranks'),
  new RegExp('/apis/esb/advancements/adventures'),
];

registerRoute(
  context => {
    const { pathname, search = '' } = context.url;
    return (
      dictRoutes.find(matcher => matcher.test(pathname)) &&
      !search.includes('swCache=false')
    );
  },
  idbCache.strategies.cacheFirst({
    cacheName: 'dict-api-cache',
    plugins: [replaceTargetUrlPlugin(), dictionariesExpirationPlugin],
  }),
  'GET',
);

registerRoute(
  esbApiRegex,
  idbCache.strategies.networkFirst({
    cacheName: 'esb-api-cache',
    plugins: [replaceTargetUrlPlugin(), requestExpirationPlugin],
  }),
  'GET',
);

// cacheable POST requests (GET-like, e.g. /adults)
registerRoute(
  context =>
    esbApiRegex.test(context.url.pathname) &&
    context.event.request.url.includes('swCache=true'),
  idbCache.strategies.networkFirst({
    cacheName: 'esb-api-post-cache',
    plugins: [replaceTargetUrlPlugin(), requestExpirationPlugin],
  }),
  'POST',
);

batchRegisterRoute(
  esbApiRegex,
  idbCache.strategies.networkOnly({
    plugins: [replaceTargetUrlPlugin()],
  }),
  ['POST', 'PUT', 'PATCH', 'DELETE'],
);

//
// WEBSCRIPT a.k.a. REPORTS a.k.a. VIM2
//
const webscriptApiRegex = /\/vim2\//;
batchRegisterRoute(
  webscriptApiRegex,
  idbCache.strategies.networkOnly({
    plugins: [replaceTargetUrlPlugin()],
  }),
  ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
);

//
// DISCOURSE LAMBDA
//
const discourseLambdaApiRegex = /\/apis\/discourse-lambda\//;
batchRegisterRoute(
  discourseLambdaApiRegex,
  idbCache.strategies.networkOnly({
    plugins: [replaceTargetUrlPlugin()],
  }),
  ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
);

//
// 3rd PARTY STATIC ASSETS
//

// google fonts
const oneYearInSeconds = 60 * 60 * 24 * 365;
registerRoute(
  new RegExp(/^https:\/\/fonts\.googleapis\.com/),
  new CacheFirst({
    cacheName: 'google-fonts',
    plugins: [
      new ExpirationPlugin({
        maxAgeSeconds: oneYearInSeconds,
        maxEntries: 30,
      }),
    ],
  }),
);

// images
const thirtyDaysInSeconds = 60 * 60 * 24 * 30;
registerRoute(
  new RegExp(
    /^https:\/\/\w+\.cloudfront\.net.*(?:png|gif|jpg|jpeg|webp|svg)$/i,
  ),
  new CacheFirst({
    cacheName: 'images',
    plugins: [
      new ExpirationPlugin({
        maxAgeSeconds: thirtyDaysInSeconds,
        maxEntries: 2000,
      }),
    ],
  }),
);
